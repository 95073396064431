.pet-details-option {
    &__btn {
        display: flex;
        align-items: center;
        padding: 1rem;
        font-weight: 500;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background: #fff;

        .description {
            font-size: 0.8rem;
            font-weight: 400;
            color: #444444;
            margin: 0.25rem 0;
        }

        ion-fab {
            position: inherit;

            ion-fab-button {
                width: 50px;
                height: 50px;
                margin-right: 1rem;
            }

            img {
                width: 40px;
            }
        }
    }
}