.add-payment-form {
  .credit-card {
    ion-item {
      ion-input {
        margin-left: 1rem;
      }
    }
  }

  ion-label {
    h1 {
      font-size: 20px !important;
      font-weight: 500;
    }
  }

  &__creditCard-container {
    border: 2px solid rgba(211, 211, 211, 0.3);
    border-radius: 10px;
    margin: auto 1rem;
  }

  &__company-select {
    margin-left: 0.75rem;
  }

  &__card-container {
    display: flex;
  }

  &__input-mask {
    all: unset;
    width: 100%;
    margin-left: 1rem;
  }

  &__submit-btn {
    margin-top: 1.5rem;
  }

  .aux-mt{
    margin-top: 0.5rem !important;
  }
}