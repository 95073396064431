.siniestro-info {
  ion-grid {
    ion-row {
      display: flex;
      align-items: center;
    }
  }

  &__avatar {
    display: flex;
    justify-content: center;

    img {
      object-fit: cover;
      width: 90px;
      height: 90px;
      border-radius: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      @media (min-width: 500px) {
        width: 100px;
        height: 100px;
      }
    }
  }

  &__info-container {
    position: relative;
  }

  &__nombre-date-box {
    display: flex;
    align-items: center;
    // font-weight: 500;
  }

  &__nombre {
    color: var(--ion-color-primary);
    font-size: 1.3rem;
    font-weight: 500;
  }

  &__denuncia {
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9rem;
    // font-weight: 500;
    margin-top: 2%;
    // color: var(--ion-color-dark);
  }

  &__date {
    font-size: 0.8rem;
    position: absolute;
    right: 0;
  }
}
