.personal-data-form {
  &__title {
    font-size: 1.4rem;
    font-weight: 500;
  }

  &__button-container {
    margin: 1rem 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .personal-data-form {
    &__radio-group {
      width: 70%;
    }
  }
}