.auth-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--app-linear);
  height: 100vh;

  &__row {
    text-align: center;
    &--form {
      z-index: 1;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    &--img {
      margin: 20px 0;
      object-fit: contain;
      width: 300px;
    }
    &--pet-img {
      position: absolute;
      bottom: 0;
      z-index: 0;
    }
  }
  ion-item {
    --background: transparent !important;
  }
}

@media only screen and (max-width: 800px) {
  .auth-view {
    &__row--img {
      width: 230px;
    }
  }
}
