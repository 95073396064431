.libreta-vacunacion-popover {
    --backdrop-opacity: 0.5 !important;
    --background: linear-gradient(to bottom, #fff 15%, #F8F8F8);

    .container {
        color: #000 !important;

        .goBack {
            position: absolute;
            top: 0;
            left: 0;
            margin: 1rem;
            font-size: 25px;
            color: var(--ion-color-dark);
        }

        img {
            width: 120px;
            margin-top: 0.5rem;
        }

        .title {
            font-size: 1.2rem;
            font-weight: 500;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(211, 211, 211, 0.5);
        }

        .vacunaColocada {
            span {
                color: var(--ion-color-primary);
            }

            .info {
                text-align: justify;
                text-justify: inter-word;
                font-weight: 500;
                color: var(--ion-color-dark);
            }

            .photoInfo {
                @extend .info;
                font-weight: 400;
                font-style: italic;
            }

            ion-button {
                margin-top: 1rem !important;
            }
        }

        .options {
            ion-col {
                display: flex;
                justify-content: center;
            }

            .option {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                width: 120px;
                height: 120px;
                padding: 0.5rem;
                border-radius: 25px;
                background: #F6F6F6;

                font-size: 0.8rem;
                font-weight: 500;
                color: var(--ion-color-dark);

                img {
                    width: 50px;
                }
            }
        }

        .form-box {
            text-align: start;

            p {
                margin: 1rem 0 0.5rem !important;
                text-align: justify;
                text-justify: inter-word;
                font-weight: 500;
                color: var(--ion-color-dark);
            }

            ion-item {
                ion-select {
                    width: 100% !important;
                    padding-right: 0.25rem !important;
                }
            }
        }
    }

    div.popover-content {
        padding: 1rem !important;
        width: 75vw;

        @media (min-width: 550px) {
            width: 66vw;
        }

        @media (min-width: 950px) {
            width: 50vw;
        }

        height: fit-content;
        text-align: center;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -75%) !important;
    }
}

.see-libreta-vacunacion__popover {
    --backdrop-opacity: 0.5 !important;

    .container {
        .seeLibreta-slide {
            img {
                width: 300px;
                padding: 2rem;
            }
        }
    }

    div.popover-content {
        width: 90vw;

        @media (min-width: 550px) {
            width: 66vw;
        }

        @media (min-width: 950px) {
            width: 50vw;
        }

        height: fit-content;
        text-align: center;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}