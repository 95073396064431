.crear-evento-vacuna-form {
  //   padding: var(--padding-form-card);
  //   &__row {
  //     display: flex;
  //     align-items: center;
  //     &--label {
  //       flex-grow: 1;
  //     }
  //     &--date {
  //       width: 100%;
  //       text-align: right;
  //     }
  //   }
  ion-label {
    font-weight: 500;
    color: var(--ion-color-primary);
  }
}
