.carnet-asociado-popover {
  --backdrop-opacity: 0.5 !important;
  --background: linear-gradient(to bottom, #fff 15%, #f8f8f8);

  ion-grid {
    width: 100% !important;
    position: relative;
    background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);

    ion-col {
      text-align: start;
      color: var(--ion-color-dark);

      .top-box {
        display: flex;
        flex-direction: column;
        justify-content: start;
        border-bottom: 1px solid rgba(211, 211, 211, 0.5);

        img {
          width: 90px;
          margin-left: -0.3rem;

          @media (min-width: 700px) {
            width: 125px;
          }
        }

        p {
          font-size: 0.9rem;
          font-weight: 500;
          color: var(--ion-color-dark);
          margin-top: 0 !important;
        }
      }
    }

    .carnetAsociado_profilePic {
      img {
        max-width: 100%;
        max-height: 75%;

        @media (min-width: 900px) {
          max-height: 66%;
        }

        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
    }

    .data {
      margin-bottom: 0.4rem;

      .title {
        font-size: 0.8rem;
        font-weight: 500;
        color: var(--ion-color-dark) !important;
      }

      .info {
        font-size: 0.85rem;
      }
    }

    .pet_topInfo-box {
      display: flex;
      justify-content: space-between;
    }

    .downloadIcon {
      font-size: 30px;
      position: absolute;
      top: 0;
      right: 0;
      margin: 1rem;
    }

    .downloadedImg {
      @extend .downloadIcon;
      width: 33px;
    }

    .pawPrint {
      margin-top: 0.75rem;
      width: 75px;
    }

    .emision {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0.6rem;
      font-size: 0.66rem;
      font-weight: 500;
      color: var(--ion-color-medium-shade);
    }
  }

  div.popover-content {
    width: 90vw;

    @media (min-width: 550px) {
      width: 66vw;
    }

    @media (min-width: 950px) {
      width: 50vw;
    }

    height: fit-content;
    text-align: center;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
