.confirm-page {
  text-align: center;

  ion-header {
    height: 12vh;
    margin: 0;
    display: flex;
    justify-content: center;

    background-color: #fff;

    img {
      object-fit: contain;
      width: 300px;
    }
  }

  h6 {
    font-size: 17px;
    text-align: justify;
    text-justify: inter-word;
  }

  p {
    margin: 0;
  }

  &__tel-email-box {
    display: flex;
    flex-direction: column;

    ion-icon {
      font-size: 40px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    a {
      font-size: 19px;
      text-decoration: none;
    }
  }

  &__redes {
    // font-weight: 500;
  }

  ion-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ion-icon {
      margin-top: 1rem;
      font-size: 50px;
    }
  }
}
