.svf-summary {
  ion-row {
    padding: var(--padding-form-card);
    ion-col {
    }
  }

  &__type {
    // text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--ion-color-primary);
  }

  &__key {
    font-weight: 500;
  }

  &__value {
    font-size: 0.8rem;
  }

  &__border-bottom {
    border-bottom: 1px solid rgba(211, 211, 211, 0.3);
  }
}
