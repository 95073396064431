@font-face {
  font-family: 'Ionicons';
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1');
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix') format('embedded-opentype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1') format('truetype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1') format('woff'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.indicacion-enviada {
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;

  ion-grid {
    ion-row {
      ion-col {
        ion-icon {
          color: darkorange;
        }
      }
    }
  }

  &__state-icon {
    display: flex;
    justify-content: center;

    ion-icon {
      font-size: 56px;
    }
  }

  &__type {
    // font-size: 0.95rem;
    // font-weight: 500;
    // color: var(--ion-color-dark);
  }

  &__date {
    margin: 0.5rem 0;
    font-size: 0.85rem;
    color: var(--ion-color-primary);
  }

  &__description {
    margin-bottom: 0.66rem;
    color: var(--ion-color-medium-shade);
  }

  &__bottom-container {
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9rem;

    &_veterinario {
      color: var(--ion-color-primary);
    }
  }

  .Collapsible__trigger {
    &:after {
      color: #000;
      font-size: 16px;
      font-family: 'Ionicons';
      content: '\f123';
      position: absolute;
      right: 1rem;
      top: 1rem;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  }
}