.form-add-pet-1 {
  ion-grid {
    ion-row {
      display: flex;
      align-items: center;
    }
  }

  &__radio {
    display: flex;
    justify-content: space-around;
    align-items: center;

    ion-item {
      ion-label {}

      ion-radio {
        margin-right: 7.5px;
      }
    }
  }

  ion-button {
    margin: 0 !important;

  }

  &__selectorDisabled {
    pointer-events: none;
    opacity: 0.5;
  }
}