.pet {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &__avatar {
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 100%;
    box-shadow: #c3cfe2 0px 4px 12px;

    margin-bottom: -3px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    margin-top: 12px;
    // font-weight: 500;
  }

  &__aux-margin {
    margin-bottom: 2rem !important;
  }
}