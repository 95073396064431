.show-siniestros-page {
  ion-content {
    --background: linear-gradient(to bottom, #fff 1%, #f8f8f8);
  }

  ion-col {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-bottom: 32px;
    // font-weight: 500;
  }

  &__pic {
    img {
      object-fit: cover;
      width: 90px;
      height: 90px;
      border-radius: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      @media (min-width: 500px) {
        width: 100px;
        height: 100px;
      }
    }
  }

  &__nombre {
    font-size: 1.4rem;
    color: var(--ion-color-primary);
  }

  &__raza {
    font-size: 0.9rem;
  }
}
