.form-item {
  display: flex;
  flex-direction: column;

  &__error-message {
    transition: opacity 0.3s linear;
    margin-top: 3.3px;
    font-size: 14px;
    color: var(--ion-color-danger);
    text-align: center;
  }
}

.has-error {
  ion-item {
    --border-color: var(--ion-color-danger);
    --highlight-color-focused: var(--ion-color-danger);
  }
}
