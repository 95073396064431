.nutricion-menu-popover {
    --backdrop-opacity: 0.5 !important;
    --background: linear-gradient(to bottom, #fff 15%, #F8F8F8);

    .title {
        font-size: 1.2rem;
        font-weight: 500;
        color: #000 !important;

        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(211, 211, 211, 0.5);
    }

    img {
        width: 100px;
        margin: 0 auto;
    }

    .options {
        color: #000 !important;

        .option {
            display: flex;
            align-items: center;
            font-size: 0.95rem;
            font-weight: 500;
            margin-top: 1rem;
            cursor: pointer;

            ion-icon {
                font-size: 20px;
                margin-right: 0.75rem;
                padding: 0.5rem;
                border-radius: 50%;
                color: var(--ion-color-light);
                background: var(--ion-color-dark);
            }
        }
    }

    div.popover-content {
        padding: 1rem !important;
        width: 75vw;

        @media (min-width: 550px) {
            width: 66vw;
        }

        @media (min-width: 950px) {
            width: 50vw;
        }

        height: fit-content;
        text-align: center;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}