.siniestro-card {
  display: flex;
  align-items: center;
  min-height: 75px;
  font-size: 0.85rem;
  border-radius: 10px;
  // box-shadow: #c3cfe2 0px 4px 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;

  ion-col {
    display: flex;
    align-items: center;
  }

  &__pic {
    img {
      margin: auto;
      width: 30px;

      @media (min-width: 500px) {
        width: 45px;
      }
    }
  }

  &__date {
    font-weight: 400;
    margin-bottom: 2.5% !important;
    color: var(--ion-color-primary);
  }

  &__denuncia {
    font-size: 0.9rem;
    // font-weight: 500;

    p {
      margin: 0;
    }
  }

  ion-icon {
    font-size: 1.2rem;
    margin-left: 1rem;
  }

  .aux-mb {
    margin-bottom: 0.1rem;
  }

  .allSiniestrosPetData {
    margin-bottom: 0.5rem;

    .nombre {
      font-weight: 500;
      padding-right: 0.5rem !important;
    }
  }
}
