.video-llamada-form {
  &__block {
    text-align: justify;
    margin: 15px;
    margin-top: 30px;
    padding: 5px;
    border: 2px solid var(--ion-color-primary);
    border-radius: 2%;
  }
  &__info {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__black {
    font-size: medium;
  }
  &__verde {
    font-size: large;
    color: var(--ion-color-primary);
  }
  &__whatsapp {
    display: flex;
    align-items: center;
    background-color: white;
    position: absolute;
    right: 0;
    bottom: 0;
    &__text {
      font-size: large;
    }
    &__img {
      height: 4em;
      width: 4em;
    }
  }
}
