.home-page {
  &__loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: #ebebeb;
  }

  ion-content {
    --background: linear-gradient(to bottom, #fff 1%, #F8F8F8);
  }
}