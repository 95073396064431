.pet-details-page {
  ion-content {
    --background: linear-gradient(to bottom, #fff 15%, #f8f8f8);
  }

  &__img-container {
    .profile {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .profile img {
      width: 350px;
      height: 350px;
      object-fit: cover;
      border-radius: 100%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }

    .profile__spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  &__details {
    .top-data {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nombre {
        p {
          // color: var(--ion-color-primary);
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 0.5rem !important;
        }

        span {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          color: var(--ion-color-dark);

          ion-icon {
            box-shadow: none;
            padding: 0 !important;
            margin-left: -0.15rem;
            margin-right: 0.25rem;
          }
        }
      }

      ion-icon {
        font-size: 20px;
        padding: 0.5rem;
        border-radius: 100%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }

    .data-boxes {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0 1.5rem;

      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85px;
        height: 85px;
        text-align: center;
        border-radius: 25px;
        background: #f6f6f6;

        .title {
          font-weight: 500;
          color: var(--ion-color-primary);
          margin-bottom: 0.25rem;
        }

        .data {
          font-size: 0.8rem;
          color: var(--ion-color-dark);
        }
      }
    }
  }

  // &__image-updating {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   background: rgba(0, 0, 0, 0.3);
  //   border-radius: 5px;
  // }
}
