.form-select-or-create {
    &__label {
        font-size: 0.9rem;
        color: var(--ion-color-dark);
        text-align: justify;
        text-justify: inter-word;
        margin: 1rem 0;
    }

    &__error-message {
        text-align: center;
        margin-top: 3.3px;
        font-size: 14px;
        color: var(--ion-color-danger);
        opacity: 0;
        /* Ajusta la duración y la función de tiempo según tus preferencias */
        transition: opacity 0.5s ease-in-out;
    }

    &__error-message.show {
        opacity: 1;
    }
}