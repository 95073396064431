.form-add-doc-obligatoria {
  &__comprobanteCBU {
    font-weight: 500;
    color: var(--ion-color-dark);
  }

  &__comprobanteCBU-label {
    opacity: 1;
  }

  &__detalle-doc-extra {
    color: var(--ion-color-medium);
    font-size: 0.9rem;
  }

  &__loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }
}