.add-food-pet-item {
  ion-grid {
    ion-row {
      ion-col {
        display: flex;
        align-items: center;

        font-size: 1.3rem;
        color: var(--ion-color-primary);

        img {
          width: 75px;
          height: 75px;
          border-radius: 100%;
          object-fit: cover;
        }
      }
    }
  }

  p {
    margin: 0;
    font-size: 0.8rem;
  }

  &__pet-box {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;

    img {
      object-fit: cover;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      margin-right: 1rem;
    }
  }
  &__pet-selected {
    border: 4px solid var(--ion-color-dark);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  &__pet-selected__bold {
    font-weight: 500;
  }
}