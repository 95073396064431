.comprobante-pago-form {

}

.comprobante-pago-form-popover {
    --backdrop-opacity: 0.5 !important;
  
    div.popover-content {
      width: 75vw;
  
      /* Medium */
      @media (min-width: 600px) {
        width: 50vw;
      }
  
      /* Large */
      @media (min-width: 900px) {
        width: 33vw;
      }
  
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      padding: 1rem;
      text-align: justify;
      text-justify: inter-word;
  
      ion-grid {
        color: #000 !important;
        ion-row {
          ion-col {
            display: flex;
            align-items: center;
  
            .title {
              font-weight: 500;
              margin-bottom: 0.5rem;
            }
  
            .subtitle {
              font-size: 0.9rem;
              color: var(--ion-color-medium-shade);
            }
          }
        }
      }
    }
  }
  