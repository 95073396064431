.photo-gallery {
  ion-toolbar {
    ion-icon {
      font-size: 25px;
    }
  }

  ion-grid {
    // overflow: scroll;
    width: 100%;
  }

  ion-col {
    height: 400px !important;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }

  .gallery {
    /* Asegura que ocupe toda la pantalla en dispositivos móviles */
    max-height: 100vh;

    /* Permite el desplazamiento vertical si hay desbordamiento */
    overflow-y: auto;

    /* Fondo blanco para la galería que coincida con la barra de desplazamiento */
    background-color: white;

    ion-grid {
      width: 100%;
    }

    ion-row {
      display: flex;
      flex-wrap: wrap;
    }

    ion-col {
      height: 150px !important;

      // /* Ajuste para tablets y pantallas medianas */
      // @media (min-width: 768px) {
      //   height: 75px;
      // }

      // /* Ajuste para pantallas grandes */
      // @media (min-width: 1024px) {
      //   height: 125px;
      // }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }

      ion-icon {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 20px;
        margin: 0.55rem;
        padding: 0.25rem;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(3.5px);
        -webkit-backdrop-filter: blur(3.5px);
      }
    }
  }

  .pdf-container {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Sombra alrededor del PDF */
    background-color: #fff; /* Fondo blanco para el PDF */
    border-radius: 8px; /* Esquinas redondeadas */
    padding: 16px; /* Espacio interno */
    position: relative; /* Necesario para ajustar el tamaño */
  }

  .pdf-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pdf-document {
    max-width: 100%;
    max-height: 100%;
  }
}
