.settings-page {
  &__infoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;

    ion-icon {
      font-size: 90px;
      color: var(--ion-color-primary);
    }
  }

  &__name {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--ion-color-primary);
  }

  &__mail {
    font-size: 1rem;
    color: var(--ion-color-medium);
  }

  ion-item {
    font-size: 1.1rem;

    ion-icon {
      font-size: 26px;
      margin-right: 16px;
      color: var(--ion-color-primary);
    }
  }

  // &__options-logOut-opacity {
  //   opacity: 0.3;
  // }
}