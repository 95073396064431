.pets-page {
  text-align: center;

  &__title {
    margin: 2rem !important;
    font-size: 1.25rem;
    color: var(--ion-color-dark);
  }

  .swiper {
    position: relative;

    .swiper-pagination-bullets {
      position: absolute;
      bottom: 62px;
    }
  }

  &__pet-container {
    img {
      object-fit: cover;
      width: 250px;
      height: 250px;
      border-radius: 30%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      background: #239D70;
    }

    p {
      margin-bottom: 0.25rem !important;
    }

    .label {
      margin-top: 2rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--ion-color-primary);
    }

    .raza {
      color: var(--ion-color-dark);
    }
  }
}