.reintegro-indicacion {
  &__full-width {
    width:100%;
    max-width:100%;
  }
    // collapsible
  &__top-container {
    display: flex;
    align-items: center;

    ion-icon {
      font-size: 50px;
    }
  }
  
  &__comprobante {
    display: flex;
    justify-content: flex-end;
    // @media (min-width: 500px) {
    // }
  }

  
}
