.navigation-bar {
  ion-header {
    ion-toolbar {
      ion-item {
        ion-button {
          height: fit-content;
        }

        img {
          width: 95px;
        }

        .upload-icon {
          font-size: 30px;
        }
      }
    }
  }
}