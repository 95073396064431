.password-recover-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  &__loading-spinner {
    width: 12px;
    margin-left: 10px;
  }
  
  &__button {
    border-radius: 50%;  
  }
  
  }