.alert {
}

.primary {
  font-weight: 500 !important;
}

.secondary {
  font-weight: 400 !important;
}

.delete {
  font-weight: 500 !important;
  color: var(--ion-color-danger) !important;
}
