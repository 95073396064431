.vet-call-page {
  img {
    display: flex;
    margin: 0 auto !important;
    width: 120px;
  }

  b {
    color: var(--ion-color-dark) !important;
  }

  &__text {
    margin: 10px;
    text-align: justify;
  }

  &__num {
    margin: 10px;
    font-weight: bold;
    font-size: large;
  }

  span {
    color: var(--ion-color-primary) !important;
    font-weight: 500;
  }
}