.step {
  &__title {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 7px;
    color: #000 !important;
  }
  &__body {
    font-size: 0.9rem;
    margin-bottom: 7px;
    color: #000 !important;
  }
  &__hint {
    font-weight: bold;
    font-size: 0.8rem;
    color: #000 !important;
  }
}
