body {
  color: #000 !important;
  overflow: scroll;
}

ul {
  padding: 0 1rem;

  li {
    margin: 0.5rem 0;
    font-weight: 500;
  }
}

.green {
  font-weight: 500;
  color: var(--ion-color-primary);
}

.prev {
  position: absolute !important;
  left: 40%;
  bottom: 0%;
  z-index: 1;
}

.next {
  position: absolute !important;
  right: 40%;
  bottom: 0%;
  z-index: 1;
}

.omit {
  z-index: 1;
  position: absolute !important;
  top: 5%;
  margin: 0.5rem;

  ion-icon {
    font-size: 25px;
    color: #000 !important;
  }
}

.text_wrapper {
  padding: 35px;
  text-align: left;
  font-size: medium;
}

.bg_image {
  background-image: var(--background-image,
      url('../../assets/InitialSlides/desktop/Fondo1.png'));

  height: 100vh;
  width: 100vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding-top: 2rem;
}

@media only screen and (max-width: 700px) {
  .bg_image.img1 {
    --background-image: url('../../assets/InitialSlides/mobile/Fondo1.png') !important;
  }

  .bg_image.img2 {
    --background-image: url('../../assets/InitialSlides/mobile/Fondo2.png') !important;
  }

  .bg_image.img3 {
    --background-image: url('../../assets/InitialSlides/mobile/Fondo3.png') !important;
  }

  .bg_image.img4 {
    --background-image: url('../../assets/InitialSlides/mobile/Fondo4.png') !important;
  }
}

@media only screen and (min-width: 700px) {
  .text_wrapper {
    padding: 35px;
    font-size: large;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
    max-height: 70%;

    p {
      text-align: left;
    }

    img {
      max-width: 40%;
      max-height: 40%;
    }

    ul {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .bg_image.img1 {
    --background-image: url('../../assets/InitialSlides/desktop/Fondo1.png') !important;
  }

  .bg_image.img2 {
    --background-image: url('../../assets/InitialSlides/desktop/Fondo2.png') !important;
  }

  .bg_image.img3 {
    --background-image: url('../../assets/InitialSlides/desktop/Fondo3.png') !important;
  }

  .bg_image.img4 {
    --background-image: url('../../assets/InitialSlides/desktop/Fondo4.png') !important;
  }
}