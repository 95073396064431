.movimientos {

  &__heading {
    h1 {
      font-size: 1.35rem;
      // color: var(--ion-color-dark);
    }

    p {
      margin: 0;
      text-align: justify;
      text-justify: inter-word;
      font-size: 0.9rem;
      color: var(--ion-color-dark);
    }
  }

  &__loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }
}