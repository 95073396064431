.add-food-form {
  &__label {
    font-weight: 500;
    color: var(--ion-color-primary);
  }

  &__subtitle {
    color: var(--ion-color-primary);
    font-size: 1.1rem;
  }

  &__pets-to-feed {
    display: flex;
    font-style: italic;
    color: var(--ion-color-primary);
  }

  &__food-info {
    border: 2px solid rgba(158, 158, 150, 0.4);
    border-radius: 10px;
  }

  &__notSameRaza {
    font-weight: 500;
    color: var(--ion-color-danger);
  }

  &__noFood {
    font-weight: 500;
    color: var(--ion-color-danger);
  }

  &__disabled {
    pointer-events: none;
  }
}