.siniestro-menu {
  &__heading {
    h1 {
      display: flex;
      font-size: 24px;
    }

    p {
      margin: 0;
      text-align: justify;
      text-justify: inter-word;
      font-size: 0.9rem;
      color: var(--ion-color-dark);
    }

    .bold {
      font-weight: 500;
      color: var(--ion-color-primary);
    }
  }

  ion-grid {
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #fff;

    ion-col {
      display: flex;
      align-items: center;
    }

    ion-icon {
      font-size: 20px;
    }
  }

  &__title-box {
    padding: 1.5rem 1rem !important;
    // font-weight: 500;
  }

  &__img-box {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #fff;

    img {
      width: 50px;
    }

    // ion-icon {
    //   font-size: 46px !important;
    // }
  }

  &__img-box_reintegro {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #fff;

    img {
      width: 50px;
    }
  }

  &__badge {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: -10px;
    right: -5px;
    width: 25px;
    height: 25px;
    border-radius: 100%;

    background-color: var(--ion-color-danger);
    color: #fff;
  }
}