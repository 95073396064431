.nutricional-info-page {
  // For desktop
  max-width: 800px;
  margin: auto;
  padding: 0 10px;
  // For all
  width: 100%;
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: bold;
    font-size: large;
    text-align: center;
  }
  &__info {
    display: flex;
    justify-content: center;
  }
  &__divider {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgb(212, 212, 212);
    margin: 10px;
    padding: 0;
  }
  &__block {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  &__noInfo {
    font-size: small;
    color: var(--ion-color-primary);
  }
}
