.documentacion-obligatoria {
  &__heading {
    h1 {
      font-size: 1.35rem;
      // color: var(--ion-color-dark);
    }

    p {
      margin: 0;
      text-align: justify;
      text-justify: inter-word;
      font-size: 0.9rem;
      color: var(--ion-color-dark)
    }

    .bold {
      font-weight: 500;
      color: var(--ion-color-primary);
    }
  }
}
