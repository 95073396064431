.form-add-pet-2 {
  ion-grid {
    ion-row {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
    }

    ion-col {
      position: relative;

      span {
        position: absolute;
        right: 0;
      }

      img {
        width: 55px;
      }
    }
  }

  &__table-title {
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__column-title {
    color: var(--ion-color-primary);
    font-weight: 500;
  }

  &__selected-row:hover {
    background: rgba(211, 211, 211, 0.2);
    cursor: pointer;
  }

  &__row-border {
    border-bottom: 1px solid lightgray;
  }

  &__newMedioPago-btn {
    position: relative;

    ion-icon {
      position: absolute;
      left: 0;
      font-size: 25px;
    }
  }

  &__popover {
    --backdrop-opacity: 0.5 !important;
    div.popover-content {
      width: 90vw;
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  ion-button {
    margin: 0 !important;
  }
}
