/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Custom Variables **/

:root {
  --app-linear: linear-gradient(#b2b2b2, white);
  --padding-form-card: 10px;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #1ea473;
  --ion-color-primary-rgb: 30, 164, 115;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1b9468;
  --ion-color-primary-tint: #35ad81;

  /** secondary **/
  --ion-color-secondary: #47d39e;
  --ion-color-secondary-rgb: 71, 211, 158;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #40be8e;
  --ion-color-secondary-tint: #59d7a8;

  /** tertiary **/
  --ion-color-tertiary: #dbf7e8;
  --ion-color-tertiary-rgb: 219, 247, 232;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #c5ded1;
  --ion-color-tertiary-tint: #dff8ea;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #444444;
  --ion-color-dark-rgb: 68, 68, 68;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #363636;
  --ion-color-dark-tint: #696969;

  /** medium **/
  --ion-color-medium: #9e9e9e;
  --ion-color-medium-rgb: 158, 158, 158;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #7e7e7e;
  --ion-color-medium-tint: #b1b1b1;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
