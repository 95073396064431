.all-siniestros-page {
  ion-content {
    // --background: url(../../assets/WaveLine4.svg) no-repeat fixed center / cover,
    //   #fff;
    --background: linear-gradient(to bottom, #fff 15%, #f8f8f8);
  }

  .swiper {
    padding: 1rem 0.5rem;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: -0.25rem !important;
    }
  }

  .swiperSlide {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .selected_pet {
    img {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
}
