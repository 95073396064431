.home-navbar {
  ion-header {
    ion-toolbar {
      display: flex;
      align-items: center;
      padding: 0 0 1rem;
    }
  }

  &__logo-container {
    img {
      width: 90px;
      margin-left: 0.5rem;
    }
  }

  &__bordered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    padding: 0.4rem;
    border-radius: 10px;
    box-shadow: #c3cfe2 0px 4px 6px;
  }
}