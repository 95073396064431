.sync-pets-page {
  &__info-container {
    text-align: justify;

    span {
      color: var(--ion-color-primary);
      font-weight: 500;
    }
  }

  &__text-ok {
    text-align: center;
    color: var(--ion-color-success);
  }

  &__text-error {
    text-align: center;
    color: var(--ion-color-danger);
  }
}
