.home-menu-modal {
  &__show-modal-icon {
    display: flex;
    font-size: 22px;
    color: var(--ion-color-dark);
  }

  &__container {
    margin-top: 2rem;
    overflow: scroll;
  }

  &__logo-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 134.166px;
      height: 58.833px;
    }

    ion-icon {
      font-size: 24px;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    cursor: pointer;

    ion-icon {
      font-size: 32px;
      color: var(--ion-color-primary);
    }

    p {
      font-size: 17px;
      margin-left: 20px;
      color: #000;
    }
  }
}