.siniestro-popover {
  &__title {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--ion-color-primary);
    margin-bottom: 5px;
  }

  &__denuncia {
    text-align: justify;
    text-justify: inter-word;
  }

  &__popover {
    --backdrop-opacity: 0.4 !important;
    font-size: 1.1rem;
    div.popover-content {
      width: 85vw;
      padding: 20px;
      text-align: center;
      border-radius: 20px;
      position: absolute;
      top: 30% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
}
