.nutrition-add-page {
  ion-button {
    --box-shadow: none;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    h3 {
      margin-bottom: 0;
      color: var(--ion-color-dark);
    }

    img {
      width: 80px;
    }
  }
}