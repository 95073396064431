.edit-event-page {
  ion-header {
    ion-toolbar {
      .delete {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        margin-right: 1rem;
        font-size: 28px;
        color: var(--ion-color-danger);
      }
    }
  }

  &__content {
    // @media only screen and (min-width: 800px) {
    //   width: 50%;
    //   margin: auto;
    // }
    position: relative;

    &__title {
      margin: 0 2vh;
      padding-bottom: 3vh;
      border-bottom: 1px solid lightgray;

      text-align: center;
      font-weight: 500;
      font-size: 1.6rem;
      color: var(--ion-color-primary);
    }

    &__birthday-img {
      display: flex;
      justify-content: center;

      img {
        width: 220px;
      }
    }
  }

  &__cumpleaños {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--ion-color-primary);
  }

  &__spinner {
    color: var(--ion-color-primary);
    margin: 50px;
    width: 40px;
    height: 40px;
  }

  &__toast-error {
    --button-color: var(--ion-color-danger);
  }

  &__toast-success {
    --button-color: var(--ion-color-primary);
  }
}
