.nutrition-page {
  &__noFood {
    ion-col {
      text-align: center;
      font-size: 1.3rem;
      font-weight: 500;
      color: var(--ion-color-dark);

      img {
        width: 180px;
      }
    }
  }

  .divButton {
    display: flex;
    justify-content: center;

    ion-button {
      --box-shadow: none;
    }
  }

  &__toast {
    --button-color: var(--ion-color-danger);
  }
}
