@font-face {
  font-family: 'Ionicons';
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1');
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix')
      format('embedded-opentype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1')
      format('truetype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1')
      format('woff'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

.vet-form-completado {
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
  margin-bottom: 1rem;

  &__state-icon {
    display: flex;
    justify-content: center;

    ion-icon {
      font-size: 56px;
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  &__type {
    max-width: 85%;
    text-align: justify;
    text-justify: inter-word;
    // font-size: 0.95rem;
    // font-weight: 500;
    // color: var(--ion-color-dark);
  }

  &__date {
    margin: 0.5rem 0;
    font-size: 0.85rem;
    color: var(--ion-color-primary);
  }

  &__vet {
    text-align: start;
    text-justify: inter-word;
    font-size: 0.85rem;
    margin-top: 0.6rem;
    color: var(--ion-color-medium-shade);
  }

  &__top-grid {
    font-size: 0.9rem !important;

    .title {
      font-weight: 500;
    }

    .value {
      font-size: 0.8rem !important;
    }
  }

  &__bottom-container {
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9rem;
  }

  .Collapsible__trigger {
    &:after {
      color: #000;
      font-size: 16px;
      font-family: 'Ionicons';
      content: '\f123';
      position: absolute;
      right: 1rem;
      top: 1rem;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  }
}
