.login-page-links {
  text-align: center;
  font-weight: 500;

  &--reg {
    color: var(--ion-color-dark);

    a {
      color: var(--ion-color-primary);
    }
  }

  &--forget {
    color: #fff;
  }
}