.movimiento {
  ion-grid {
    position: relative;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #fff;
  }

  &__title-box {
    padding: 1.66rem 1rem !important;
  }

  &__title {
    font-size: 0.875rem;
    font-weight: 500;
    // color: var(--ion-color-dark);
  }

  &__date {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
    font-size: 0.72rem;
    font-weight: 500;
    color: var(--ion-color-primary);
  }

  &__icon-box {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    ion-icon {
      font-size: 45px;
    }
  }

  .confirm {
    background: var(--ion-color-primary);
  }

  .warning {
    background: darkorange;
  }
}