.add-vet-contact-modal {
}

.add-vet-contact__popover {
  --backdrop-opacity: 0.5 !important;
  div.popover-content {
    width: 85vw;
    height: fit-content;
    text-align: center;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
