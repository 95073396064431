.add-pet-button {
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    border: 2px solid var(--ion-color-primary);

    ion-icon {
      font-size: 45px;
    }
  }
}
