.consultas-indicaciones {
  &__heading {
    h1 {
      font-size: 1.35rem;
      // color: var(--ion-color-dark);
    }

    ion-button {
      --box-shadow: none;
      margin: 0 !important;
    }

    i {
      color: var(--ion-color-medium-shade);
      font-size: 0.9rem;
    }
  }

  .misssingData_pic {
    display: flex;
    margin: 0 auto;
    width: 125px;
    height: 125px;
    object-fit: cover;
    margin-bottom: 3rem;
  }

  .missingData_title {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
  }

  &__loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
