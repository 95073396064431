.edit-data-popover {
  --backdrop-opacity: 0.5 !important;
  --background: linear-gradient(to bottom, #fff 15%, #f8f8f8);

  img {
    display: flex;
    margin: 0 auto;
    width: 155px;
    padding-bottom: 1rem;

    @media (min-width: 700px) {
      width: 150px;
    }
  }

  .logo-container {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(211, 211, 211, 0.5);
  }

  div.popover-content {
    padding: 1rem !important;
    width: 75vw;

    @media (min-width: 550px) {
      width: 66vw;
    }

    @media (min-width: 950px) {
      width: 50vw;
    }

    height: fit-content;
    text-align: center;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  &__title {
    font-size: 0.9rem;
    color: var(--ion-color-dark) !important;
  }

  &__messageContainer {
    display: flex;
    justify-content: space-evenly;
    text-align: justify;
    text-justify: inter-word;

    img {
      width: 50px;
      margin-right: 0.75rem;
    }

    margin-top: 1rem;
    font-size: 0.8rem;
    color: var(--ion-color-dark) !important;
  }

  &__itemsContainer {
    padding: 1rem 0;
    border-bottom: 1px solid rgba(211, 211, 211, 0.5);
    color: var(--ion-color-dark) !important;

    .item {
      margin-bottom: 0.3rem;
      font-size: 0.95rem;
      font-weight: 500;
    }

    .key {
      color: var(--ion-color-medium-shade);
    }
  }
}
