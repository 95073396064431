.file-uploader {
  ion-item {
    --padding-end: 0px;
    --inner-padding-end: 0px;

    ion-row {
      width: 100% !important;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    color: var(--ion-color-medium);
  }

  &__withMedia {
    display: flex;
    align-items: center;
    color: #000 !important;

    img {
      width: 40px;
      margin-right: 1rem;
    }
  }

  &__iconBox {
    display: flex;
    justify-content: end;

    ion-icon {
      font-size: 25px;
      padding: 0.5rem;
      border-radius: 50%;
      background: var(--ion-color-primary);
    }
  }

  &__iconDisabled {
    opacity: 0.5;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
