.veterinario {
  &__popover {
    --backdrop-opacity: 0.5!important;
    div.popover-content {
      width: 85vw;
      text-align: center;
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
  &__title {
    font-size: 1.1rem;
    font-weight: 400;
    border-bottom: 1px solid var(--ion-color-primary);
    color: var(--ion-color-primary);
  }
}
