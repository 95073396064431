.add-pet-page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    h3 {
      color: var(--ion-color-dark);
    }

    img {
      width: 66px;
    }
  }

  &__content {
    // @media only screen and (min-width: 800px) {
    //   width: 50%;
    //   margin: auto;
    // }
  }
}
