.vet-form-comprobante-input {
  display: flex;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 2px solid rgba(212, 212, 212, 0.8);
  border-radius: 10px;

  ion-col {
    display: flex;
    align-items: center;
  }

  img {
    display: flex;
    margin: 0 auto;
    width: 50px;
  }

  p {
    margin: 0 !important;
    font-weight: 500;
    color: var(--ion-color-dark);
  }

  &__extra-image-updating {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.3);
  }
}