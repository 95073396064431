
.baja-cobertura-page {
    ion-content {
        --background: linear-gradient(to bottom, #fff 15%, #F8F8F8);
    }

    img {
      display: flex;
      margin: 0 auto !important;
      width: 120px;
    }
  
    b {
      color: var(--ion-color-dark) !important;
    }

    &__title {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--ion-color-dark);
    }
  
    &__text {
      text-align: justify;
    }
  
    span {
      color: var(--ion-color-primary) !important;
      font-weight: 500;
    }
  }