.crear-evento-first-form {

  ion-icon {
    font-size: 40px;
    margin-bottom: 1rem;
  }

  ion-label {
    font-weight: 500;
    color: var(--ion-color-primary);
  }

  ion-button {
    --box-shadow: none;
  }

  &__row {
    &--radio {
      display: flex;
    }
  }
}

.crear-evento-info__popover {
  --backdrop-opacity: 0.5 !important;
  // padding: 1rem !important;

  ion-icon {
    font-size: 66px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  span {
    color: #000;
    text-align: justify;
    text-justify: inter-word;
  }

  .popover-btn-container {
    display: flex;
    justify-content: space-between;

    ion-button {
      width: 45%;
    }
  }

  div.popover-content {
    padding: 1rem !important;
    width: 85vw;
    height: fit-content;
    text-align: center;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}