.tabs-page {
  ion-content {
    --padding-bottom: 10vh;
  }

  ion-tab-bar {
    height: 6.5vh;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: #c3cfe2 0px 4px 12px;
    --background: #fff;
  }

  ion-tab-button {
    --color: var(--ion-color-dark);

    &::before {
      display: block;
      content: '';
      margin: 0 auto;
      width: 33px;
      height: 3px;
    }

    &.tab-selected::before {
      background-color: var(--ion-color-primary);
    }
  }
}
