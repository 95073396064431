.food-info-item-modal {
  &__edit-food-modal {
    color: #000;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .header-img {
      display: flex;
      justify-content: center;

      img {
        width: 110px;
      }
    }

    .food-header-info {
      text-align: center;
      font-weight: 500;

      .nombre-marca-box {
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(211, 211, 211, 0.3);
      }

      .nombre {}

      .marca {
        color: var(--ion-color-primary);
        font-size: 1.2rem;
      }
    }

    .option {
      position: relative;
      display: flex;
      align-items: center;

      ion-icon {
        font-size: 10px;
        color: var(--ion-color-dark);
      }

      p {
        font-size: 17px;
        margin-left: 10px;
        color: #000;
      }

      .edit {
        position: absolute;
        right: 0;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}

.foodBag__popover {
  --backdrop-opacity: 0.5 !important;

  div.popover-content {
    width: 70vw;
    height: fit-content;
    text-align: center;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .header-img {
    width: 66px;
    margin: 2rem auto 1rem;
  }
}

.pets {
  color: #000 !important;

  .e {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    img {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 1rem;
    }

    p {
      font-weight: 500;
    }

    ion-icon {
      position: absolute;
      right: 0;
      margin-right: 1rem;
      font-size: 28px;
    }
  }

  .e-disabled {
    @extend .e;
    opacity: 0.5;
  }
}