ion-content {
  background-color: #fff !important;
  z-index: 0;
}

#_capacitor-camera-input {
  display: none;
}

.error-msg {
  font-size: small;
  color: var(--ion-color-danger);
}

.button-spinner {
  width: 15px;
  margin-left: 10px;
}

.label-floating {
  --color: var(--ion-color-medium) !important;

  &:focus {
    --color: var(--ion-color-primary) !important;
  }
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-primary) !important;
}

.swiper-pagination-bullet {
  background: black;
}

ion-button {
  --box-shadow: none;
}

ion-fab-button {
  --box-shadow: none;
}

//FIX PORQUE CHROME ROMPIO POPOVER DE IONIC 5. REAL FIX => UPGRADE IONIC 6.
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

//Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

//No underline in any link
a:-webkit-any-link {
  text-decoration: none;
}