.weight-history-modal {
  width: 100vw;
  height: 100vh;
  margin: auto;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title-span {
    font-size: 1rem;
    font-weight: 500;
    color: var(--ion-color-primary);
  }
  &__odd-row {
    background-color: rgba(30, 164, 115, 0.25);
  }
}
