.form-add-siniestro {
  &__heading {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        max-width: 75% !important;
        color: var(--ion-color-dark);
      }
    }

    img {
      width: 35px;
    }

    span {
      font-size: 1.1rem;
      color: var(--ion-color-primary);
    }
  }

  &__form {
    ion-label {
      min-width: 110% !important;
    }

    ion-item {
      --border-color: grey;
      --background: transparent;
    }

    ion-textarea {
      --placeholder-opacity: 0.75;
      --placeholder-font-style: italic;
      --placeholder-color: var(--ion-color-primary);

      text-align: justify;
      text-justify: inter-word;
    }
  }

  ion-button {
    --border-radius: 10px;
    --box-shadow: none;
    margin-top: 5vh;
  }
}