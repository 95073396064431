.home-cards {
  .swiper-slide {
    padding: 0.1rem;
  }
  .card {
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    background: url(../../assets/CurveLine.svg) no-repeat center center / cover,
      #fff;
    // box-shadow: #c3cfe2 0px 4px 12px;
    box-shadow: #c3cfe2 0px 1px 3px;
  }

  ion-row {
    display: flex;
    align-items: center;
    text-align: center;

    ion-col {
      overflow: hidden;

      img {
        max-height: 200px;
        margin-bottom: -5px;
      }

      ion-button {
        --box-shadow: none;
        --border-radius: 20px;
      }
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    color: var(--ion-color-primary);
  }

  &__subtitle {
    font-size: 1rem;
    color: var(--ion-color-dark);
  }

  .card-2 {
    @extend .card;
    background: url(../../assets/CurveLine1.svg) no-repeat center center / cover,
      #fff;

    .title {
      font-size: 1.8rem;
      font-weight: 500;
      color: #003a78;
    }

    ion-button {
      --background: #003a78;
    }
  }
}