.food-info-item {
  position: relative;
  border-bottom: 1px solid rgba(211, 211, 211, 0.3);

  ion-col {
    display: flex;
    align-items: center;

    img {
      width: 70px;
    }
  }

  &__info-box {
    p {
      margin: 0;
      margin-bottom: 0.2rem;

      font-size: 0.9rem;
    }
  }

  &__nombre {
    margin-top: 8px !important;
    font-weight: 500;
  }

  &__marca {
    color: var(--ion-color-primary);
    font-size: 1.1rem !important;
    font-weight: 500;
  }

  &__editar {
    color: var(--ion-color-medium);
    cursor: pointer;
  }

  &__delete {
    position: absolute;
    top: 0;
    right: 0;
  }  
}