.text-box {
  margin-top: 1rem;
  .textarea-wrapper {
    padding-bottom: 5px;
    border-radius: 5px;
    border: 1px solid var(--ion-color-medium);
    textarea {
      height: var(--height);
    }
    &:focus-within {
      border-color: var(--ion-color-primary);
      box-shadow: 0 0 2px var(--ion-color-primary);
    }
    &:hover {
      border-color: var(--ion-color-primary);
    }
  }
}
