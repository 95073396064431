.not-found {
  text-align: center;
  color: var(--ion-color-primary);
  &__ups {
    font-size: x-large;
  }
  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    &__pic {
      max-height: 300px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
