.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__error-msg {
    color: red;
    font-size: small;
    margin: 0;
  }

  &__loading-spinner {
    width: 12px;
    margin-left: 10px;
  }

  &__button {
    border-radius: 50%;
  }
}
